import React, { useState } from "react";
import styled from "styled-components";
import polandIco from "../images/poland-icon.png";
import ukIco from "../images/united-kingdom-icon.png";
import GermanyIco from "../images/germany-icon.png";
import arrow from "../images/drop-down-arrow.png";
import dataPL from "../PL.json";
import dataEN from "../EN.json";
export default function LangSwitch({ setCurrentLoc, currentLoc }) {
   const [currentFlag, setCurrentFlag] = useState(polandIco);
   const [opened, setOpened] = useState(false);
   const changeLoc = (lang) => {
      setOpened(!opened);
      switchLoc(lang);
   };
   const switchLoc = (loc) => {
      switch (loc) {
         case "PL":
            setCurrentLoc(dataPL);
            setCurrentFlag(polandIco);
            break;
         case "EN":
            setCurrentLoc(dataEN);
            setCurrentFlag(ukIco);
            break;
         // case "GR":
         //    setCurrentLoc(dataGR)
         // setCurrentFlag(GermanyIco);
         //    break;
         default:
            setCurrentLoc(dataEN);
      }
   };

   // const [currentLang, setCurrentLang] = useState(0);
   return (
      <DropDown>
         <LangButton onClick={() => setOpened(!opened)}>
            <div className="flex items-center justify-start">
               <img src={currentFlag} alt="" />
            </div>
         </LangButton>
         <Option className={opened ? null : "closed"}>
            <div onClick={() => changeLoc("PL")}>
               <img src={polandIco} alt="" /> PL
            </div>
            <div onClick={() => changeLoc("EN")}>
               <img src={ukIco} alt="" /> EN
            </div>
            {/* <div>
               <img src={GermanyIco} alt="" /> DE
            </div> */}
         </Option>
      </DropDown>
   );
}

const DropDown = styled.div`
   position: relative;
   width: 92px;
   height: 50px;
   z-index: 9999;
   background: #1c174c;
   border-radius: 8px;
   ::before {
      content: ${`url(${arrow})`};
      position: absolute;
      right: 10px;
      top: 14px;
      z-index: 999;
   }
   .closed {
      display: none;
   }
`;
const Option = styled.div`
   position: absolute;
   top: 60px;
   width: 100%;
   background-color: #fff;
   overflow: hidden;
   border: 1px solid #1c174c;
   border-radius: 8px;
   padding: 12px 19px;
   div {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
      margin-bottom: 10px;
   }
`;
const LangButton = styled.div`
   padding: 12px 19px;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   border: none;
   outline: none;
   cursor: pointer;
`;
