import React, { useState } from "react";
import Button from "./Button";
import logo from "../images/viramity-logo.png";
import hamburger from "../images/hamburger-menu.png";
import close from "../images/close-menu.png";
import styled from "styled-components";
import LangSwitch from "./LangSwitch";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { Link } from "react-router-dom";
// import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
export default function Navigation({ data, setCurrentLoc, currentLoc }) {
   const [isMenuOpen, setIsMenuOpen] = useState(false);

   return (
      <NavigationPanel>
         <div className="container mx-auto ">
            <div className="flex justify-between py-4">
               <div className="lg:flex  hidden">
                  <Link to="/">
                     <div className="lg:flex gap-2 items-center hidden">
                        <img src={logo} alt="viramity-logo" />
                        <Logotype>Viramity</Logotype>
                     </div>
                  </Link>
               </div>

               <NavbarList className="flex flex-wrap justify-between lg:justify-end items-center gap-6 w-full px-4">
                  <ul className="hidden lg:flex items-center gap-6 ">
                     <ScrollLink activeClass="active" className="" to="oferta" spy={true} smooth={true} duration={1500}>
                        <li>{data.menu[0]}</li>
                     </ScrollLink>
                     <ScrollLink activeClass="active" className="" to="funkcje" spy={true} smooth={true} duration={1500}>
                        <li>{data.menu[1]}</li>
                     </ScrollLink>
                     <ScrollLink activeClass="active" className="" to="dlaKogo" spy={true} smooth={true} duration={1500}>
                        <li>{data.menu[2]}</li>
                     </ScrollLink>
                     <ScrollLink activeClass="active" className="" to="kontakt" spy={true} smooth={true} duration={1500}>
                        <li>{data.menu[3]}</li>
                     </ScrollLink>
                  </ul>
                  <div className="hidden lg:block">
                     <Button text={data.buttonContent} />
                  </div>

                  <Link to="/">
                     <div className="lg:hidden gap-2 items-center flex lg:mb-4">
                        <img className="logo-mobile" src={logo} alt="viramity-logo" />
                     </div>
                  </Link>
                  <LangSwitch setCurrentLoc={setCurrentLoc} currentLoc={currentLoc} />
                  <div className="flex lg:hidden items-center justify-center" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                     <div>
                        <img src={isMenuOpen ? close : hamburger} alt="" />
                     </div>
                  </div>
               </NavbarList>
            </div>
         </div>
         <AgencyBar>
            <div className="container mx-auto  text-right py-1">
               <Link to="/agency">{data.agencyLink}</Link>
            </div>
         </AgencyBar>
         {isMenuOpen && (
            <OpenedMobileMenu>
               <NavbarList className="flex flex-col   gap-6">
                  <ul className="flex flex-col  gap-6 ">
                     <Link activeClass="active" className="" to="oferta" spy={true} smooth={true} duration={1500}>
                        <li>{data.menu[0]}</li>
                     </Link>
                     <Link activeClass="active" className="" to="funkcje" spy={true} smooth={true} duration={1500}>
                        <li>{data.menu[1]}</li>
                     </Link>
                     <Link activeClass="active" className="" to="dlaKogo" spy={true} smooth={true} duration={1500}>
                        <li>{data.menu[2]}</li>
                     </Link>
                     <Link activeClass="active" className="" to="kontakt" spy={true} smooth={true} duration={1500}>
                        <li>{data.menu[3]}</li>
                     </Link>
                  </ul>
                  <div className="block">
                     <Button text={"Demo"} />
                  </div>
               </NavbarList>
            </OpenedMobileMenu>
         )}
      </NavigationPanel>
   );
}

const NavigationPanel = styled.div`
   position: fixed;
   z-index: 99;
   width: 100vw;
   background-color: white;
   .logo-mobile {
      width: 45px;
   }
`;
const OpenedMobileMenu = styled.div`
   position: absolute;
   top: 113px;
   background-color: #f5f8fd;
   padding: 24px;
   width: 100vw;
   height: 100vh;
   li {
      margin: 0;
      font-size: 24px;
   }
`;
const Logotype = styled.h2`
   color: #ff5670;
   font-weight: 700;
   font-size: 24px;
   text-transform: uppercase;
`;

const AgencyBar = styled.h2`
   background-color: #95a6e5;
   color: white;
   text-decoration-line: underline;
   font-weight: 300;
   font-size: 16px;
`;

const NavbarList = styled.div`
   li {
      list-style: none;
      cursor: pointer;
   }
`;
