import styled, { css, keyframes } from "styled-components";

const zoomIn = keyframes`
    0% {
        transform: translate(-50%, -50%) scale(0);
        opacity: 0;
    }
    70% {
        transform: translate(-50%, -50%) scale(1.05);
        opacity: 1;
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
`

const animated = css`
    animation: ${zoomIn} .7s forwards;
`

export const AwardContainerStyled = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    background-color: #f5f8fd;
    z-index: 22;
    width: 90%;
    /* height: 90%; */
    padding: 30px 0;
    border-radius: 15px;
    border: 3px solid #fff;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15);
    opacity: 0;
    ${(props) => props.animated ? animated : ''}
`

export const AwardTextStyled = styled.p`
    font-size: 44px;
    font-weight: 900;
    line-height: 54px;
    text-align: center;
    color: #1c174c;
    margin: 0;
    @media (max-width: 700px){
        font-size: 32px;
        line-height: 40px;
    }
    @media (max-width: 700px){
        font-size: 26px;
        line-height: 32px;
    }
`

export const AwardButtonStyled = styled.button`
    background: #1c174c;
    color: #fff;
    margin: auto;
    display: block;
    padding: 10px 30px;
    font-size: 22px;
    border-radius: 8px;
    margin-top: 25px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover{
        background: rgb(40, 32, 117);
        box-shadow: 0 0 1rem rgba(40, 32, 117, 0.16);
    }

    @media (max-width: 700px){
        font-size: 18px;
    }
    @media (max-width: 430px){
        font-size: 15px;
    }
`