import react, { useState } from "react";
import Homepage from "./pages/Homepage";
import Navigation from "./components/Navigation";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Agency from "./pages/Agency";
import Policies from "./pages/Policies";
import Footer from "./components/Footer";
import { AnimatePresence } from "framer-motion";
import dataPL from "./PL.json";
import dataEN from "./EN.json";
function App() {
   const [currentLoc, setCurrentLoc] = useState(dataPL);
   return (
      <div className="relative">
         <BrowserRouter>
            <Navigation data={currentLoc.navigation} setCurrentLoc={setCurrentLoc} currentLoc={currentLoc} />
            <AnimatePresence exitBeforeEnter>
               <Routes>
                  <Route path="/" element={<Homepage documentData={currentLoc} />} />
                  <Route path="/agency" element={<Agency documentData={currentLoc} />} />
                  <Route path="/policies" element={<Policies documentData={currentLoc.policies} />} />
               </Routes>
            </AnimatePresence>
            <Footer />
         </BrowserRouter>
      </div>
   );
}

export default App;
