import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
*{
    box-sizing: border-box;
}
  body {
    margin: 0;
    padding: 0;
    font-family: 'Lexend';
    font-weight: 400;
    color: #1C174C;
  }
  .desktop-wrapper{
    max-width: 1920px;
    margin: auto
  }
  .container{
    width: 1280px;
    @media only screen and (max-width: 1024px) {
width: 100%;
padding-left: 13px;
padding-right: 13px;
}
  }
  .basic-pl{
    padding-left: 80px;    
  }
  .basic-pr{
    padding-right: 80px;   
  }
  .basic-p{
    padding:0px 80px;   
  }
  span{
    color: #FF5670;
}
  h3{
    font-weight: 700;
font-size: 24px;
line-height: 32px;
margin-bottom: 20px;
@media only screen and (max-width: 1024px) {
    margin-top: auto;
    transform: none;
   }

  }
    p, li{
        font-size: 16px;
    }
    p{
      margin-bottom:20px;
    }
li{
  list-style-type: disc;
  margin-left: 18px;
}
.absolute-center{
  margin-top: 65%;
  transform: translateY(-50%);
  @media only screen and (max-width: 1024px) {
    margin-top: auto;
    transform: none;
   }
}
.top-margin{
  padding-top: 128px;
  @media only screen and (max-width: 1024px) {
    padding-top: 182px;
   }
}









.swiper-pagination-bullet {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid #1C174C;
  margin-inline: 4px;
  background-color: #f5f8fd;
  cursor: pointer;
  opacity: 1;

  &.swiper-pagination-bullet-active{
    background-color: #1C174C;
  }
}
.scroll-button{
cursor: pointer;
.white-fill, .dark-stroke, .dark-fill{
  transition: all 0.5s;
}

}
.scroll-button:hover{
transition: all 0.5s;

  .white-fill{
    fill: rgb(28,23,76);
    transition: all 0.5s;
  }
  .dark-stroke{
    stroke: #FF5670;
    transition: all 0.5s;
   
  }
  .dark-fill{
    fill: #FF5670;
    transition: all 0.5s;
  }
}

.swiper-slide{
  padding-bottom: 20px;
  @media (max-width: 767px){
    align-self: stretch;
    height: auto;
  }
}
.swiper-slide-tablet{
  @media (max-width: 1024px){
    align-self: stretch;
    height: auto;
  }
}
.swiper-grid-column>.swiper-wrapper {
    flex-wrap: wrap;
    flex-direction: row;
}
`;
