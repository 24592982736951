import styled from "styled-components";

export const InputContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    border: 1px solid #1c174c;
    overflow: hidden;
    border-radius: 8px;
    transition: all .2s;

    &:focus-within {
       border-color: #F5F8FD;
    }
`