import { SlotsIconsContainerStyled, SlotsIconStyled, SlotsRollStyled } from "./roll.styles"
import roll from '../../../../images/minigames/roll.svg'
import { useRef } from "react"

const Roll = ({ animate, duration, amount, initial_position }) => {
    const ref = useRef()
    return (
        <SlotsRollStyled ref={ref}>
            <div className='absolute left-0 top-0 w-full h-6 bg-gradient-to-b from-[#00000030] to-transparent bg-opacity-50 z-20'></div>
            <div className='absolute left-0 bottom-0 w-full h-6 bg-gradient-to-t from-[#00000030] to-transparent bg-opacity-50 z-20'></div>
            <SlotsIconsContainerStyled animate={animate} duration={duration} amount={amount * (ref.current?.offsetWidth / 100)} initial_position={initial_position * (ref.current?.offsetWidth / 100)}>
                <SlotsIconStyled src={roll} alt="" />
                <SlotsIconStyled src={roll} alt="" />
                <SlotsIconStyled src={roll} alt="" />
            </SlotsIconsContainerStyled>
        </SlotsRollStyled>
    )
}

export default Roll