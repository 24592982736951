import React from "react";
import parse from "html-react-parser";
export default function EventsDesc({ data }) {
   return (
      <div className="mb-4">
         <h3>{parse(data.heading)}</h3>
         <p>{parse(data.paragraph)}</p>
         <ul>{parse(data.contentList)}</ul>
      </div>
   );
}
