import React from "react";
import styled from "styled-components";
import ForWho from "../components/forWho/ForWho";
import OurWork from "../components/ourWork/OurWork";
import Opinions from "../components/opinions/opinions";
import Faq from "../components/faq/Faq";
import Services from "../components/Services/Services";
import Offer from "../components/offer/Offer";
import Hero from "../components/Hero";
import Contact from "../components/contact/Contact";
import { useScroll } from "../components/useScroll";
import { motion } from "framer-motion";
import { sliderContainer, slider, pageAnimation, fade, photoAnim, lineAnim } from "../animations";
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from "react-scroll";
// import dataPL from "../PL.json";

const lightBlue = "#F5F8FD";
const white = "#fff";
// const documentData = dataPL;
export default function Homepage({ documentData }) {
   return (
      <>
         <motion.div initial="hidden" animate="show" exit="exit">
            <motion.div variants={sliderContainer}>
               <Frame1 variants={slider}></Frame1>
               <Frame2 variants={slider}></Frame2>
               <Frame3 variants={slider}></Frame3>
               <Frame4 variants={slider}></Frame4>
            </motion.div>
            <motion.div variants={pageAnimation} initial="hidden" animate="show" exit="exit">
               <Section theme={{ bgColor: lightBlue }} className="top-margin">
                  <Hero heroData={documentData.homepage.hero} />
               </Section>

               <Element name="oferta">
                  <Section theme={{ bgColor: white }}>
                     <div className="container mx-auto  py:12 lg:py-24">
                        <Offer offerData={documentData.homepage.offer} />
                     </div>
                  </Section>
               </Element>
               <Section>
                  <OurWork data={documentData.homepage.ourWork} />
               </Section>
               <Element name="funkcje">
                  <Section theme={{ bgColor: white }}>
                     <div className="container mx-auto  py:12 lg:py-24">
                        <Services data={documentData.homepage.services} />
                     </div>
                  </Section>
               </Element>
               <Section>
                  <Opinions data={documentData.homepage.opinions} />
               </Section>
               <Element name="dlaKogo">
                  <Section theme={{ bgColor: white }}>
                     <div className="container mx-auto  py:12 lg:py-24">
                        <ForWho data={documentData.homepage.forWho} />
                     </div>
                  </Section>
               </Element>
               <Section theme={{ bgColor: lightBlue }}>
                  <div className="container mx-auto  py:12 lg:py-24">
                     <Faq data={documentData.homepage.faq} />
                  </div>
               </Section>
               <Element name="kontakt">
                  <Section theme={{ bgColor: white }}>
                     <div className="container mx-auto  py:12 lg:py-24">
                        <Contact data={documentData.homepage.contact} />
                     </div>
                  </Section>
               </Element>
            </motion.div>
         </motion.div>
      </>
   );
}

const Section = styled.div`
   position: relative;
   overflow: hidden;
   width: 100%;
   background-color: ${(props) => (props.theme.bgColor !== "" ? props.theme.bgColor : null)};
`;
const Frame1 = styled(motion.div)`
   position: fixed;
   left: 0;
   top: 10%;
   width: 100%;
   height: 100vh;

   background: #fdb400;
   z-index: 2;
`;
const Frame2 = styled(Frame1)`
   background: #a27ceb;
`;
const Frame3 = styled(Frame1)`
   background: #ff5670;
`;
const Frame4 = styled(Frame1)`
   background: #36d058;
`;
