import { Field, ErrorMessage } from 'formik'
import { InputContainer } from "./input.styles"

const CustomTextarea = ({ field, form, ...props }) => {
    return <textarea {...field} {...form} {...props} rows={4} className="w-full p-3 outline-none font-normal" placeholder='Wiadomość' />
}

const Input = ({ name, placeholder, icon, type }) => {
    return (
        <div className="my-2">
            <InputContainer>
                <div className="w-12 h-full flex justify-center pt-3.5">
                    <img src={icon} alt="icon" className="h-5" />
                </div>
                {
                    type === 'textarea' ?
                        <Field name={name} id="answer" component={CustomTextarea} />
                        :
                        <Field name={name} className="w-full p-3 outline-none font-normal" placeholder={placeholder} />
                }
            </InputContainer>
            <ErrorMessage name={name} >{msg => <div className="text-xs text-red-400" >{msg}</div>}</ErrorMessage>
        </div>
    )
}

export default Input