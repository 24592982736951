import styled from "styled-components";

export const SlotsContainerStyled = styled.div`
    padding: 30px 10px 50px;
    background-color: #a27ceb;
    border-radius: 5px;
    box-shadow: 0 1rem 0 #673cba;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;

    @media (max-width: 640px){
        width: 100%;
    }
    @media (max-width: 550px){
        padding: 30px 10px 30px;
    }
`

export const SlotsRollsStyled = styled.div`
    display: flex;
    background-color: #673cba;
    justify-content: center;
    padding: 30px;
    box-shadow: inset 0 0 .5rem rgba(0,0,0,.45);
    width: 100%;

    @media (max-width: 550px){
        padding: 15px;
    }
`

export const SlotsButtonStyled = styled.div`
    background-color: #ff5670;
    box-shadow: 0 .5rem 0 0 #7e2634;
    color: #fff;
    border: none;
    padding: 10px 100px;
    font-size: 22px;
    display: block;
    margin: auto;
    margin-top: 50px;
    border-radius: 5px;
    cursor: pointer;
    transition: all .1s;

    @media (max-width: 550px){
        margin-top: 30px;
    }

    &:hover{
        background-color: #fc6a81;
    }

    &:active{
        background-color: #ed455f;
        box-shadow: 0 0rem 0 0 #7e2634;
        transform: translateY(.5rem);
    }
`