import React from "react";
import Tag from "../Tag";
import FunctionsSlider from "./FunctionsSlider";
import parse from "html-react-parser";
import { motion } from "framer-motion";
import { useScroll } from "../useScroll";
import { scrollReveal } from "../../animations";
export default function Services({ data }) {
   const [element, controls] = useScroll();
   return (
      <motion.div variants={scrollReveal} animate={controls} initial="hidden" ref={element} className="pt-4 lg:mb-0">
         <Tag text={data.tag} />
         <h3>{parse(data.servicesDescription.heading)}</h3>
         <p>{parse(data.servicesDescription.paragraph)}</p>
         <FunctionsSlider functions={data.functions} />
      </motion.div>
   );
}
