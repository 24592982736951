import { useState } from 'react'
import star from '../../../../images/minigames/star_dark.svg'
import { CardFaceStyled, MemoryCardContainerStyled } from "./memory_card.styles"

const MemoryCard = ({ id, flipped, val, flipCard, blocked }) => {

    return (
        <MemoryCardContainerStyled flipped={flipped} onClick={() => (!blocked && !flipped) && flipCard(id)}>
            <CardFaceStyled>
                <img src={star} alt="Ember" />
            </CardFaceStyled>
            <CardFaceStyled color={val} className="front">
                <img src={star} alt="Memory Card" />
            </CardFaceStyled>
        </MemoryCardContainerStyled>
    )
}

export default MemoryCard