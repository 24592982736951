import React from "react";
import Tag from "../components/Tag";
import styled from "styled-components";
import { motion } from "framer-motion";
import parse from "html-react-parser";
import { sliderContainer, slider, pageAnimation, fade, photoAnim, lineAnim } from "../animations";
import FormComponent from "../components/contact/FormComponent";
import BlueFrame from "../components/BlueFrame";
import TrustedAgencies from "../components/TrustedAgencies";

export default function Agency({ documentData }) {
   return (
      <motion.div initial="hidden" animate="show" exit="exit" className="top-margin">
         <motion.div variants={sliderContainer}>
            <Frame1 variants={slider}></Frame1>
            <Frame2 variants={slider}></Frame2>
            <Frame3 variants={slider}></Frame3>
            <Frame4 variants={slider}></Frame4>
         </motion.div>
         <motion.div variants={pageAnimation} initial="hidden" animate="show" exit="exit">
            <BlueFrame />
            <Section>
               <div className="container mx-auto py-24">
                  <Tag text={documentData.agency.tag} />
                  <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 lg:gap-24">
                     <div className="lg:col-span-7 order-last lg:order-first">
                        <h3>{parse(documentData.agency.sections[0].heading)}</h3>
                        <p>{parse(documentData.agency.sections[0].paragraphs[0])}</p>
                        <p>{parse(documentData.agency.sections[0].paragraphs[1])}</p>

                        <h3>{parse(documentData.agency.sections[1].heading)}</h3>
                        <p>{parse(documentData.agency.sections[1].paragraphs[0])}</p>

                        <ul>
                           <li>{parse(documentData.agency.sections[1].list[0])}</li>
                           <li>{parse(documentData.agency.sections[1].list[1])}</li>
                           <li>{parse(documentData.agency.sections[1].list[2])}</li>
                           <li>{parse(documentData.agency.sections[1].list[3])}</li>
                           <li>{parse(documentData.agency.sections[1].list[4])}</li>
                           <li>{parse(documentData.agency.sections[1].list[5])}</li>
                           <li>{parse(documentData.agency.sections[1].list[6])}</li>
                           <li>{parse(documentData.agency.sections[1].list[7])}</li>
                        </ul>

                        <h3 className="mt-4 ">{parse(documentData.agency.sections[2].heading)}</h3>

                        <p>{parse(documentData.agency.sections[2].paragraphs[0])}</p>

                        <ul>
                           <li>{parse(documentData.agency.sections[2].list[0])}</li>
                           <li>{parse(documentData.agency.sections[2].list[1])}</li>
                           <li>{parse(documentData.agency.sections[2].list[2])}</li>
                        </ul>
                        <p>{parse(documentData.agency.ending)}</p>
                     </div>

                     <div className="lg:col-span-5">
                        <GreenBox>
                           <h3>{parse(documentData.agency.greenbox)}</h3>
                        </GreenBox>
                        <FormComponent data={documentData.homepage.contact.formContent} />
                     </div>
                  </div>
                  <div className="text-center mt-24">
                     <h3>{parse(documentData.agency.agenciesHeading)}</h3>
                     <TrustedAgencies />
                  </div>
               </div>
            </Section>
         </motion.div>
      </motion.div>
   );
}
const Section = styled.div`
   position: relative;
   overflow: hidden;
   width: 100%;
   background-color: ${(props) => (props.theme.bgColor !== "" ? props.theme.bgColor : null)};
`;

const GreenBox = styled.div`
   text-align: center;
   background: #36d058;
   color: white;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   border-radius: 16px;
   padding: 30px 15px;
   h3 {
      margin: 0;
   }
   margin-bottom: 1.5rem;
`;
const Frame1 = styled(motion.div)`
   position: fixed;
   left: 0;
   top: 10%;
   width: 100%;
   height: 100vh;

   background: #fdb400;
   z-index: 2;
`;
const Frame2 = styled(Frame1)`
   background: #a27ceb;
`;
const Frame3 = styled(Frame1)`
   background: #ff5670;
`;
const Frame4 = styled(Frame1)`
   background: #36d058;
`;
