import React, { useState } from "react";
import Button from "../Button";
import DropDown from "./DropDown";
import Tag from "../Tag";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useScroll } from "../useScroll";
import { scrollReveal } from "../../animations";
import parse from "html-react-parser";

export default function Faq({ data }) {
   const [element, controls] = useScroll();
   const [isVisible, setIsVisible] = useState(false);
   const toggleDropDown = () => {
      setIsVisible(!isVisible);
   };
   return (
      <FaqSection variants={scrollReveal} animate={controls} initial="hidden" ref={element} className="pt-4 lg:mb-0">
         <Tag text={data.tag}></Tag>
         <h3>{parse(data.faqkDescription.heading)}</h3>
         <p>{parse(data.faqkDescription.paragraph)}</p>

         <DropDownWrapper className={`drop-down ${isVisible ? "active" : "closed"}`}>
            <DropDown data={data.faqs} />
         </DropDownWrapper>

         <div className="block lg:hidden mb-8" onClick={() => toggleDropDown()}>
            <Button text={"Pokaż sekcje FAQ"} />
         </div>
      </FaqSection>
   );
}
const FaqSection = styled(motion.div)`
   .drop-down.active {
      height: 100%;
   }
   .drop-down.closed {
      @media only screen and (max-width: 1024px) {
         display: none;
      }
   }
   .drop-down {
      @media only screen and (max-width: 1024px) {
         height: 0;
      }
   }
`;
const DropDownWrapper = styled.div``;
