import { InputContainer } from "../input/input.styles"
import { useFormikContext, useField } from 'formik'
import { useCallback, useState } from "react"
import { useDropzone } from 'react-dropzone'

const FileInput = ({ name, error, placeholder, icon }) => {
    const [file, setFile] = useState(null)
    const { setFieldValue } = useFormikContext()
    const [field] = useField(name)

    const onDrop = useCallback(async acceptedFiles => {
        const MAX_SIZE = 8 * 1048576
        console.log(acceptedFiles)
        if (acceptedFiles[0].size <= MAX_SIZE) {
            setFile(acceptedFiles[0])
            setFieldValue(name, acceptedFiles[0])
        }
    }, [file])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    return (
        <div className="my-2">
            <InputContainer {...field} {...getRootProps()}>
                <div className="w-12 h-full flex justify-center pt-3.5">
                    <img src={icon} alt="icon" className="h-5" />
                </div>
                <div className="w-full flex items-center ">
                    <p className={`m-0 ${file === null ? 'text-gray-400' : 'text-[#1c174c]'} px-3 py-3`}>{file === null ? 'Załącznik' : file.name}</p>
                </div>
                <input {...getInputProps()} className="w-full p-3 outline-none font-normal" />
            </InputContainer>
            {error && <p className="text-sm text-[#FF5670] mb-0 mt-0.5">Błąd</p>}
        </div>
    )
}

export default FileInput