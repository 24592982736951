import styled from "styled-components"

export const SlotsRollStyled = styled.div`
    background-color: #fff;
    width: calc(33% - 10px);
    aspect-ratio: 1;
    overflow: hidden;
    margin-inline: 20px;
    box-shadow: inset 0 .5rem 2rem rgba(0,0,0,1), inset 0 -.5rem .5rem rgba(0,0,0,.16), 0 0 1rem rgba(0,0,0,.25);
    position: relative;

    @media (max-width: 550px){
        width: calc(33% - 5px);
        margin-inline: 10px;
    }
`

export const SlotsIconsContainerStyled = styled.div`
    transition: all ${(props) => props.duration} linear;
    transform: translateY(${(props) => props.animate ? '-' + props.amount + 'px' : '-' + props.initial_position + 'px'});
`

export const SlotsIconStyled = styled.img`
    width: 100%;
    transition: all 3s;
`