import React, { useState, useRef } from "react";
import styled from "styled-components";
// import parse from "html-react-parser";
// import leftScroll from "../images/left-hex-scroll.png";
// import rightScroll from "../images/right-hex-scroll.png";
import { ReactComponent as LScroll } from "../../images/scroll_L.svg";
import { ReactComponent as RScroll } from "../../images/scroll_R.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import parse from "html-react-parser";
import { PaginationContainerStyled } from "../swiper.styles";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import { Grid, Navigation, Pagination } from "swiper";

import func1 from "../../images/functions-images/func1.png";
import func2 from "../../images/functions-images/func2.png";
import func3 from "../../images/functions-images/func3.png";
import func4 from "../../images/functions-images/func4.png";
import func5 from "../../images/functions-images/func5.png";
import func6 from "../../images/functions-images/func6.png";
import func7 from "../../images/functions-images/func7.png";
import func8 from "../../images/functions-images/func8.png";
import func9 from "../../images/functions-images/func9.png";

const darkBlue = "#1C174C";
export default function FunctionsSlider({ functions }) {
   const prevRef = useRef(null);
   const nextRef = useRef(null);
   const [currentFunction, setCurrentFunction] = useState(functions[0]);
   const switchFunction = (index) => {
      setCurrentFunction(functions[index]);
   };
   const setImage = (src) => {
      switch (src) {
         case "func1":
            return func1;
            break;
         case "func2":
            return func2;
            break;
         case "func3":
            return func3;
            break;
         case "func4":
            return func4;
            break;
         case "func5":
            return func5;
            break;
         case "func6":
            return func6;
            break;
         case "func7":
            return func7;
            break;
         case "func8":
            return func8;
            break;
         case "func9":
            return func9;
            break;
         default:
            return func1;
      }
   };
   return (
      <div>
         <div className=" gap-2 hidden lg:flex mb-4">
            <LScroll className="scroll-button" ref={prevRef} />
            <RScroll className="scroll-button" ref={nextRef} />
         </div>
         <div className=" flex items-center">
            <Swiper
               slidesPerView={1}
               grid={{
                  rows: 1,
                  fill: "row",
               }}
               spaceBetween={5}
               slidesPerColumnFill="row"
               modules={[Grid, Pagination, Navigation]}
               breakpoints={{
                  1024: {
                     slidesPerView: 3,
                     spaceBetween: 10,
                     grid: { rows: 1 },
                  },
               }}
               navigation={{
                  prevEl: prevRef.current,
                  nextEl: nextRef.current,
               }}
               onInit={(swiper) => {
                  swiper.params.navigation.prevEl = prevRef.current;
                  swiper.params.navigation.nextEl = nextRef.current;
                  swiper.navigation.init();
                  swiper.navigation.update();
               }}
               // onInit={(swiper) => {
               //    swiper.init();
               //    swiper.update();
               // }}
               pagination={{
                  clickable: true,
                  el: ".custom-pagination4",
               }}
            >
               {functions.map((func, index) => {
                  return (
                     <SwiperSlide>
                        <FunctionBox key={func.heading} theme={{ color: func.color }} className="flex flex-col items-center justify-center gap-4 text-center">
                           <img src={setImage(func.imgSrc)} alt={func.heading} />
                           <h3>{func.heading}</h3>
                           <p>{func.body}</p>
                        </FunctionBox>
                     </SwiperSlide>
                  );
               })}
            </Swiper>
         </div>
         <div>
            <PaginationContainerStyled className="custom-pagination4 mt-4 mb-8 "></PaginationContainerStyled>
         </div>
      </div>
   );
}
const FunctionBox = styled.div`
   border: 1px solid ${(props) => props.theme.color};
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   border-radius: 16px;
   color: ${(props) => props.theme.color};
   padding: 50px 12px;
`;
