import { ArrowStyled, SpinButtonStyled, WheelOfFortuneContainerStyled, WheelStyled } from "./wheel_of_fortune.styles"
import wheel from '../../../images/minigames/wheel_3.svg'
import arrow from '../../../images/minigames/location-arrow-solid.svg'
import { useState } from "react"
import Award from "../award/award"

const WheelOfFortune = ({ pause, resume }) => {
    const [animate, setAnimate] = useState(false)
    const [awardVisible, setAwardVisible] = useState(false)

    const spinWheel = () => {
        setAnimate(true)
        pause()
        setTimeout(() => {
            setAwardVisible(true)
            resume()
        }, 5300)
    }

    return (
        <>
            <WheelOfFortuneContainerStyled>
                <WheelStyled animated={animate}>
                    <img src={wheel} alt="" />
                </WheelStyled>
                <SpinButtonStyled onClick={spinWheel}>
                    <div>
                        <p>SPIN</p>
                    </div>
                    <ArrowStyled src={arrow} alt="arrow" />
                </SpinButtonStyled>
            </WheelOfFortuneContainerStyled>
            {awardVisible && < Award />}
        </>
    )
}

export default WheelOfFortune