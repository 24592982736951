import React, { useState } from "react";
import styled from "styled-components";
import event1 from "../../images/event1.png";
import event2 from "../../images/event2.png";
import event3 from "../../images/event3.png";
import event4 from "../../images/event4.png";

import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import { motion } from "framer-motion";
import { fade } from "../../animations";
import { PaginationContainerStyled } from "../swiper.styles";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import { Grid, Navigation, Pagination } from "swiper";

export default function Events({ events }) {
   const [currentClicked, setCurrentClicked] = useState(null);
   const handleClick = (index) => {
      setCurrentClicked(index);
   };
   const setImage = (src) => {
      switch (src) {
         case "event1":
            return event1;
            break;
         case "event2":
            return event2;
            break;
         case "event3":
            return event3;
            break;
         case "event4":
            return event4;
            break;

         default:
            return event1;
      }
   };
   return (
      <>
         <div className="">
            <Swiper
               slidesPerView={1}
               grid={{
                  rows: 1,
                  fill: "row",
               }}
               spaceBetween={5}
               slidesPerColumnFill="row"
               modules={[Grid, Pagination]}
               breakpoints={{
                  1024: {
                     slidesPerView: 2,
                     spaceBetween: 10,
                     grid: { rows: 2 },
                  },
               }}
               // onInit={(swiper) => {
               //    swiper.init();
               //    swiper.update();
               // }}
               pagination={{
                  clickable: true,
                  el: ".custom-pagination2",
               }}
            >
               {events.map((event, index) => {
                  return (
                     <SwiperSlide className="p-1">
                        <div onClick={() => handleClick(index)}>
                           <Card flipped={currentClicked !== index ? false : true}>
                              {currentClicked !== index ? (
                                 <ColoredBox className="flex flex-col gap-8 items-center justify-center" theme={{ bgColor: event.color }}>
                                    <img src={setImage(event.imgSrc)} alt="" />
                                    <h3>{event.text}</h3>
                                 </ColoredBox>
                              ) : (
                                 <HoveredColoredBox theme={{ bgColor: event.color }}>
                                    <h3 className="text-center">{event.back.heading}</h3>
                                    {parse(event.back.list)}
                                 </HoveredColoredBox>
                              )}
                           </Card>
                        </div>
                     </SwiperSlide>
                  );
               })}
            </Swiper>
            <PaginationContainerStyled className="custom-pagination2 mt-4 mb-8"></PaginationContainerStyled>
         </div>
      </>
   );
}

const Card = styled(motion.div)`
   transform: scale(1);
   transform-style: preserve-3d;
   transition: transform 0.5s;
   transform: ${(props) => (props.flipped ? "rotateY(180deg)" : "rotateY(0deg)")};
   cursor: pointer;
`;

const ColoredBox = styled(motion.div)`
   background-color: ${(props) => props.theme.bgColor};
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   border-radius: 16px;
   height: 270px;
   font-weight: 500;
   font-size: 32px;
   color: white;
   h3 {
      margin: 0;
   }
`;
const HoveredColoredBox = styled(motion.div)`
   background-color: white;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   border-radius: 16px;
   height: 270px;
   border: 1px solid ${(props) => props.theme.bgColor};
   font-weight: 500;
   padding: 20px;
   font-size: 32px;
   transform: rotateY(180deg);
   li {
      font-size: 14px;
   }
   color: ${(props) => props.theme.bgColor};
`;
