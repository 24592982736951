import React from "react";
import Tag from "../components/Tag";
import styled from "styled-components";
import { motion } from "framer-motion";
import { pageAnimation } from "../animations";
import BlueFrame from "../components/BlueFrame";
import parse from "html-react-parser";
const white = "#fff";
export default function Policies({ documentData }) {
   console.log(documentData.contactForm.list);
   return (
      <motion.div variants={pageAnimation} initial="hidden" animate="show" exit="exit">
         <BlueFrame />
         <Section theme={{ bgColor: white }}>
            <div className="container mx-auto py-24">
               <Tag text={parse(documentData.contactForm.tag)} />

               <h3>{parse(documentData.contactForm.heading)}</h3>

               <ol className="mb-4">
                  {documentData &&
                     documentData.contactForm.list.map((item) => {
                        return <li>{parse(item.listItem)}</li>;
                     })}
               </ol>
               <Tag text={parse(documentData.cookies.tag)} />

               <h3>{parse(documentData.cookies.heading)}</h3>
               {documentData.cookies.firstMultipleParagraphs.map((para) => {
                  return <p>{parse(para)}</p>;
               })}

               <p className="mb-0">{documentData.cookies.firstListHeading}</p>
               <ul className="mb-4">
                  {documentData.cookies.firstList.map((item) => {
                     return <li>{parse(item.listItem)}</li>;
                  })}
               </ul>

               <p className="mb-0">{documentData.cookies.secondListHeading}</p>
               <ul className="mb-4">
                  {documentData.cookies.secondList.map((item) => {
                     return <li>{parse(item.listItem)}</li>;
                  })}
               </ul>

               <p>{documentData.cookies.secondMultipleParagraphs[0]}</p>
               <p>{documentData.cookies.secondMultipleParagraphs[1]}</p>
               <Tag text={parse(documentData.privacy.tag)} />
               <h3>{parse(documentData.privacy.heading)}</h3>

               {documentData.privacy.firstMultipleParagraphs.map((para) => {
                  return <p>{parse(para)}</p>;
               })}

               <p className="mb-0">{parse(documentData.privacy.firstListHeading)}</p>
               <ul className="mb-4">
                  {documentData.privacy.firstList.map((item) => {
                     return <li>{parse(item.listItem)}</li>;
                  })}
               </ul>

               <p className="mb-0">{parse(documentData.privacy.secondListHeading)}</p>
               <ul className="mb-4">
                  {documentData.privacy.secondList.map((item) => {
                     return <li>{parse(item.listItem)}</li>;
                  })}
               </ul>

               <p className="mb-0">{parse(documentData.privacy.thirdListHeading)}</p>
               <ul className="mb-4">
                  {documentData.privacy.thirdList.map((item) => {
                     return <li>{parse(item.listItem)}</li>;
                  })}
               </ul>

               {documentData.privacy.paragrapshWithHeadings.map((item) => {
                  return (
                     <>
                        <p className="mb-0">{parse(item.heading)}</p>
                        <p>{parse(item.paragraph)}</p>
                     </>
                  );
               })}

               <p className="mb-0">{parse(documentData.privacy.fourthListHeading)}</p>
               <ul>
                  {documentData.privacy.fourthList.map((item) => {
                     if (item.sublist) {
                        return (
                           <>
                              <li>{parse(item.listItem)}</li>
                              <Sublist>
                                 {item.sublist.map((item) => {
                                    return <li>{parse(item)}</li>;
                                 })}
                              </Sublist>
                           </>
                        );
                     }
                     return <li>{parse(item.listItem)}</li>;
                  })}
               </ul>

               <p>{parse(documentData.privacy.fourthListParagraph)}</p>

               <p className="mb-0">{parse(documentData.privacy.lastParagraphWithHeading.heading)}</p>
               <p>{parse(documentData.privacy.lastParagraphWithHeading.paragraph)}</p>
               <p>{parse(documentData.privacy.contact)}</p>
            </div>
         </Section>
      </motion.div>
   );
}
const Section = styled.div`
   position: relative;
   overflow: hidden;
   width: 100%;
   background-color: ${(props) => (props.theme.bgColor !== "" ? props.theme.bgColor : null)};
   ol {
      li {
         list-style-type: decimal;
      }
   }
`;
const Sublist = styled.ul`
   margin-left: 35px;
`;
