import React, { useState } from "react";
import styled from "styled-components";
import arrow from "../../images/drop-down-arrow-dark.png";
export default function DropDown({ data }) {
   const [selected, setSelected] = useState(null);
   const toggle = (index) => {
      if (selected === index) {
         return setSelected(null);
      }
      setSelected(index);
   };
   return (
      <>
         {data.map((obj, index) => {
            return (
               <Label key={index} onClick={() => toggle(index)}>
                  <div className="flex justify-between items-center ">
                     <Title>
                        <h4>{obj.question}</h4>
                     </Title>
                     <img src={arrow} className={selected === index ? "rotated" : null} alt=" " />
                  </div>

                  <Comment className={selected === index ? "show " : null}>{obj.body}</Comment>
               </Label>
            );
         })}
      </>
   );
}
const Label = styled.div`
   border: 1px solid #1c174c;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   border-radius: 12px;
   margin-bottom: 12px;
   padding: 24px;
   cursor: pointer;
   img {
      transition: all 0.2s linear;
   }
   h4 {
      margin: 0;
      font-size: 20px;
      font-weight: 800;
   }
   .rotated {
      transform: rotate(119deg);
      transition: all 0.2s linear;
   }
   .show {
      height: auto;
      max-height: 9999px;
      transition: all 0.5s cubic-bezier(1, 0, 1, 0);
   }
`;

const Title = styled.div``;
const Comment = styled.div`
   height: auto;
   max-height: 0px;
   overflow: hidden;
   transition: all 0.5s cubic-bezier(0, 1, 0, 1);
`;
