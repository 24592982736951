import { useRef } from "react";
import Tag from "../Tag";
import { useScroll } from "../useScroll";
import { scrollReveal } from "../../animations";
import { OpinionAuthorStyled, OpinionCardStyled, OpinionWrapperStyled, PaginationContainerStyled } from "./opinions.styles";
import parse from "html-react-parser";
import { ReactComponent as LScroll } from "../../images/scroll_L.svg";
import { ReactComponent as RScroll } from "../../images/scroll_R.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";

import "swiper/css/pagination";

const Opinions = ({ data }) => {
   const [element, controls] = useScroll();
   const prevRef = useRef(null);
   const nextRef = useRef(null);

   return (
      <OpinionWrapperStyled variants={scrollReveal} animate={controls} initial="hidden" ref={element}>
         <div className="container mx-auto ">
            <div className="grid grid-cols-1 lg:grid-cols-12 gap-10 lg:gap-24 ">
               <div className="lg:col-span-5 ">
                  <div className="absolute-center">
                     <Tag text={parse(data.tag)} />
                     <h3>{parse(data.opinionsDescription.heading)}</h3>
                     <p>{data.opinionsDescription.paragraph}</p>
                     <div className="md:flex gap-2 hidden">
                        <LScroll className="scroll-button" ref={prevRef} />
                        <RScroll className="scroll-button" ref={nextRef} />
                     </div>
                  </div>
               </div>

               <div className="lg:col-span-7 flex items-center">
                  <Swiper
                     slidesPerView={1}
                     spaceBetween={20}
                     loop
                     autoplay
                     modules={[Navigation, Pagination]}
                     breakpoints={{
                        767: {
                           slidesPerView: 2,
                           spaceBetween: 50,
                        },
                     }}
                     pagination={{
                        clickable: true,
                        el: ".custom-pagination",
                     }}
                     navigation={{
                        prevEl: prevRef.current,
                        nextEl: nextRef.current,
                     }}
                     onInit={(swiper) => {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                     }}
                  >
                     {data.opinions.map((opinion, index) => {
                        return (
                           <SwiperSlide>
                              <OpinionCardStyled className="flex flex-col justify-between" theme={index % 2 !== 0 ? { color: "#1C174C", text: "white" } : { color: "white", text: "#1C174C" }}>
                                 <div className="flex-1 flex items-center">
                                    <p>{opinion.body}</p>
                                 </div>
                                 <div className="flex flex-col gap-4 text-right justify-between">
                                    <OpinionAuthorStyled>{opinion.author}</OpinionAuthorStyled>
                                    <p> {opinion.company}</p>
                                 </div>
                              </OpinionCardStyled>
                           </SwiperSlide>
                        );
                     })}
                  </Swiper>
               </div>
               <PaginationContainerStyled className="custom-pagination"></PaginationContainerStyled>
            </div>
         </div>
      </OpinionWrapperStyled>
   );
};

export default Opinions;
