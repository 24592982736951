import React from "react";
import Tag from "../Tag";
import EventsDesc from "./EventsDesc";
import Button from "../Button";
import Events from "./Events";
import { motion } from "framer-motion";
import { useScroll } from "../useScroll";
import { scrollReveal } from "../../animations";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
export default function Offer({ offerData }) {
   const [element, controls] = useScroll();

   return (
      <motion.div className="grid grid-cols-1 lg:grid-cols-12 gap-4 lg:gap-24 " variants={scrollReveal} animate={controls} initial="hidden" ref={element}>
         <div className="lg:col-span-7 order-last lg:order-first">
            <Events events={offerData.offerEventBoxes} />
         </div>

         <div className="lg:col-span-5">
            <Tag text={offerData.tag} />
            <EventsDesc data={offerData.offerDescription} />
            <div className="hidden lg:block text-right">
               <ScrollLink activeClass="active" className="" to="kontakt" spy={true} smooth={true} duration={1500}>
                  <Button text={offerData.button} />
               </ScrollLink>
            </div>
         </div>
      </motion.div>
   );
}
