import { Link } from "react-scroll";
import { AwardButtonStyled, AwardContainerStyled, AwardTextStyled } from "./award.styles";

const Award = () => {
   return (
      <AwardContainerStyled animated={true}>
         <AwardTextStyled>Gratulacje wygranej!</AwardTextStyled>
         <Link className="" to="kontakt" spy={true} smooth={true} duration={1500}>
            <AwardButtonStyled>Formularz kontaktowy</AwardButtonStyled>
         </Link>
      </AwardContainerStyled>
   );
};

export default Award;
