import Memory from '../memory/memory'
import WheelOfFortune from '../wheel_of_fortune/wheel_of_fortune'
import { BoxStyled, ContainerInnerStyled, MinigamesContainerStyled } from './container.styles'
import Slots from '../slots/slots'

const MinigamesContainer = ({ type, pause, resume }) => {
    return (
        <MinigamesContainerStyled>
            <ContainerInnerStyled>
                {type === 'wheel' && <WheelOfFortune pause={pause} resume={resume} />}
                {type === 'memory' && <Memory pause={pause} resume={resume} />}
                {type === 'slots' && <Slots pause={pause} resume={resume} />}
            </ContainerInnerStyled>
            {/* <BoxStyled src={box} alt="" /> */}
        </MinigamesContainerStyled>
    )
}

export default MinigamesContainer