import styled, { css, keyframes } from "styled-components";

const spin = keyframes`
  0% {
    transform: rotate(-15deg);
  }
  5% {
    transform: rotate(-40deg);
  }
  100% {
    transform: rotate(1470deg);
  }
`

export const WheelOfFortuneContainerStyled = styled.div`
  position: relative;
  width: 480px;
  aspect-ratio: 1;
  margin-left: 100px;

  @media (max-width: 1440px){
    margin-left: 0;
  }
`

const animated = css`
     animation: ${spin} 5s forwards cubic-bezier(0.06, 0.24, 0.3, 1.01);
`

export const WheelStyled = styled.div`
    max-width: 480px;
    aspect-ratio: 1;
    border-radius: 1000px;
    overflow: hidden;
    transform: rotate(-15deg);
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.26);

    ${props => props.animated ? animated : ''};

    & img{
        width: 100%;
        height: 100%;
    }
`


export const SpinButtonStyled = styled.div`
    width: 90px;
    aspect-ratio: 1;
    background: #fff;
    border-radius: 55px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
    z-index: 11;

    & > div{
        position: relative;
        width: 90px;
        height: 90px;
        border-radius: 55px;
        background-color: #fff;
        z-index: 20;
        display: flex;
        justify-content: center;
        align-items: center;

        & > p{
            font-size: 25px;
            display: block;
            font-weight: 700;
            color: #ff5670;
            font-family: sans-serif;
            margin-bottom: 0;
        }
    }
`

export const ArrowStyled = styled.img`
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 0;
    z-index: -1;
    transform: translate(-50%, -50%) rotate(-45deg);
    filter: drop-shadow(0 0 1rem rgba(0, 0, 0, 0.7));
`