import React from "react";
import styled from "styled-components";
import logo from "../images/viramity-logo-violet.png";
import { Link } from "react-router-dom";
const lightBlue = "#F5F8FD";

export default function Footer() {
   return (
      <Section theme={{ bgColor: lightBlue }}>
         <div className="container mx-auto">
            <div className="flex justify-center flex-col lg:flex-row lg:justify-between items-center py-5 gap-4">
               <div className="flex items-center gap-4">
                  <img src={logo} alt="Viramity" />
                  <p>Created by: JohnCube 2022</p>
               </div>
               <a href="">hello@johncube.pl</a>
               <div className="flex  justify-center flex-col lg:flex-row lg:justify-between items-center gap-4">
                  <Link to="policies">Privacy</Link>
                  <Link to="policies">Cookies</Link>
               </div>
            </div>
         </div>
      </Section>
   );
}
const Section = styled.div`
   position: relative;
   overflow: hidden;
   width: 100%;
   background-color: ${(props) => (props.theme.bgColor !== "" ? props.theme.bgColor : null)};
   p {
      margin: 0;
   }
`;
