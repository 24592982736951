import { useState } from "react"
import Award from "../award/award"
import { MemoryContainerStyled } from "./memory.styles"
import MemoryCard from "./memory_card/memory_card"

const cards = [
    {
        color: '#36d058',
        val: '1'
    },
    {
        color: '#ff5670',
        val: '2'
    },
    {
        color: '#fdb400',
        val: '3'
    },
    {
        color: '#fdb400',
        val: '3'
    },
    {
        color: '#ff5670',
        val: '2'
    },
    {
        color: '#36d058',
        val: '1'
    },
]

const Memory = ({ pause, resume }) => {
    const [currentFlipped, setCurrentFlipped] = useState([])
    const [totalFlipped, setTotalFlipped] = useState([])
    const [blocked, setBlocked] = useState(false)
    const [showAward, setShowAward] = useState(false)

    const flipCard = (id) => {
        pause()
        if (id === currentFlipped[0]) return
        if (currentFlipped.length === 0) {
            setCurrentFlipped([id])
        } else {
            setBlocked(true)
            if (cards[id].val === cards[currentFlipped[0]].val) {
                setTotalFlipped((totalFlipped) => [...totalFlipped, id, currentFlipped[0]])
                setCurrentFlipped([])
                if (totalFlipped.length === 4) {
                    setTimeout(() => {
                        setShowAward(true)
                        resume()
                    }, 400)
                }
            } else {
                setCurrentFlipped((currentFlipped) => [...currentFlipped, id])
                setTimeout(() => {
                    setCurrentFlipped([])
                }, 800)
            }
        }
        setTimeout(() => {
            setBlocked(false)
        }, 800)
    }

    return (
        <>
            <MemoryContainerStyled>
                {
                    cards.map((c, index) => {
                        return <MemoryCard blocked={blocked} key={index} id={index} flipCard={flipCard} flipped={currentFlipped.includes(index) || totalFlipped.includes(index)} val={c.color} />
                    })
                }
            </MemoryContainerStyled>
            {showAward && <Award />}
        </>
    )
}

export default Memory