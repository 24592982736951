import React from "react";
import styled from "styled-components";
export default function BlueFrame() {
   return <BlueFrameBox className="hidden lg:block"></BlueFrameBox>;
}
const BlueFrameBox = styled.div`
   background: #f5f8fd;
   height: 80px;
   width: 100%;
`;
