import React from "react";
import styled from "styled-components";
import Button from "../Button";
import { Formik, Form as FormikForm, Field } from "formik";
import Input from "./input/input";
import * as Yup from "yup";
import parse from "html-react-parser";
import person from "../../images/icons/person.png";
import at from "../../images/icons/at.png";
import attachment from "../../images/icons/attachment.png";
import building from "../../images/icons/building.png";
import comment from "../../images/icons/comment.png";
import FileInput from "./file_input/file_input";

// Przy językach te hard coded errory trzeba zmienić na klucze z obieku z errorami
const formSchema = Yup.object().shape({
   name: Yup.string().required("Pole wymagane"),
   email: Yup.string().email("Niepoprawny adres").required("Pole wymagane"),
   company: Yup.string().required("Pole wymagane"),
   message: Yup.string(),
   policy: Yup.bool().oneOf([true], "Zaznacz zgodę"),
   file: Yup.mixed(),
});

const FormComponent = ({ data }) => {
   return (
      <Formik
         validationSchema={formSchema}
         onSubmit={async (values) => {
            console.log(values);
            // TODO: Wysyłka maila
         }}
         initialValues={{
            name: "",
            email: "",
            company: "",
            message: "",
            policy: false,
            file: null,
         }}
      >
         {({ errors, touched }) => (
            <Form>
               <h3 className="text-center">{data.heading}</h3>
               <p className="text-center">{data.paragraph}</p>
               <Input name="name" placeholder="Imię*" icon={person} />
               <Input name="email" placeholder="Email*" icon={at} />
               <Input name="company" placeholder="Firma*" icon={building} />
               <Input name="message" type="textarea" placeholder="Wiadomość" icon={comment} />
               {/* <Input name="file" error={touched.file && errors.file} placeholder="Załącznik" icon={attachment} /> */}
               <FileInput name="file" placeholder="Załącznik" icon={attachment} />
               <div className="flex gap-4 text-start my-4">
                  <Checkbox type="checkbox" name="policy" error={errors.policy && touched.policy} />
                  <label htmlFor="policy" className="text-sm">
                     {parse(data.policies)}
                  </label>
               </div>
               <Button text={"Wyślij wiadomość"} type="submit" fullWidth />
            </Form>
         )}
      </Formik>
   );
};

export default FormComponent;

const Form = styled(FormikForm)`
   border: 1px solid #1c174c;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   border-radius: 16px;
   padding: 28px;
`;

const Checkbox = styled(Field)`
   position: relative;
   width: 2em;
   background-color: white;
   height: 2em;
   color: white;
   border: 1px solid ${(props) => (props.error ? "#ff5670" : "#1c174c")};
   border-radius: 10px;
   appearance: none;
   outline: 0;
   flex-shrink: 0;
   cursor: pointer;
   transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);

   ::before {
      position: absolute;
      content: "";
      display: block;
      top: 6px;
      left: 11px;
      width: 8px;
      height: 14px;
      border-style: solid;
      border-color: black;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      opacity: 0;
   }
   :checked {
      color: white;
      border-color: #e1e1e1;
      background: #ff5670;
      ::before {
         opacity: 1;
      }
      ~ label::before {
         clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
   }
`;
