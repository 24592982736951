import { SwiperSlide } from 'swiper/react'
import parse from "html-react-parser";
import leftScroll from "../../images/left-hex-scroll.png";
import rightScroll from "../../images/right-hex-scroll.png";
import MinigamesContainer from '../minigames/container/container';
import { ReactComponent as LScroll } from "../../images/scroll_L.svg";
import { ReactComponent as RScroll } from "../../images/scroll_R.svg";

const HeroSlide = ({ heading, body, game, pause, resume }) => {
    return (
        <div className="container mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 lg:gap-24">
                <div className="lg:col-span-5 flex flex-col justify-center">
                    <div className="flex items-center">
                        <div>
                            <h3 className="mb-4">{parse(heading)}</h3>
                            <p className="mb-4"> {body}</p>
                        </div>
                    </div>
                    <div className="md:flex gap-2 hidden">
                        {/* <img src={leftScroll} alt=" " className={"cursor-pointer prev-slide-games"} />
                        <img src={rightScroll} alt=" " className={"cursor-pointer next-slide-games"} /> */}
                        <LScroll className="scroll-button prev-slide-games" />
                        <RScroll className="scroll-button next-slide-games" />
                    </div>
                </div>
                <MinigamesContainer type={game} pause={pause} resume={resume} />
            </div>
        </div>
    )
}

export default HeroSlide