import React from "react";
import styled from "styled-components";
import listStyling from "../../images/list-styling.png";
import Tag from "./../Tag";
import { motion } from "framer-motion";
import { useScroll } from "../useScroll";
import { scrollReveal } from "../../animations";
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";

import { PaginationContainerStyled } from "../swiper.styles";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import { Grid, Navigation, Pagination } from "swiper";
const cards = [
   {
      heading: "AGENCJE",
      body: "Wiemy jak ważne jest indywidualne podejście do marki i Twojego Klienta, dlatego proponujemy:",
      list: [
         { listItem: "Realiacja na bazie Twojego projektu, brandu, zasad i regulaminu" },
         { listItem: "Automatyzacja weryfikacji, informowania uczestników" },
         { listItem: "Pełne dostosowanie zasad akceptacji, weryfikacji i nagród" },
         { listItem: "Intagracje (social, newstelletery, narzędzia marketingowe)" },
         { listItem: "Nasze know-how (wiemy, gdzie są miny i dziury w drodze :))" },
         { listItem: "Krótki czas realizacji" },
      ],
      color: "#A27CEB",
   },
   {
      heading: "WYDARZENIA",
      body: "Zaangażuj uczestników przed, w trakcie lub po wydarzeniu. Organizatorom szkoleń, konferencji, koncertów i innych wydarzeń proponujemy:",
      list: [
         { listItem: "Indywidualną strona wydarzenia na bazie Twojego lub naszego dedykowanego projektu" },
         { listItem: "Angażujące gry, interaktywne zabawy lub po prostu informacje o wydarzeniu" },
         { listItem: "Intagracje (social, newstelletery, narzędzia marketingowe)" },
         { listItem: "Monitrowanie zaangażowania uczestników i efektów prowadzonej kampanii" },
      ],
      color: "#FDB400",
   },
   {
      heading: "BIZNES",
      body: "Zachęć pracowników do dzielenia się wiedzą, tworzeniem i usprawnianiem organizacji. Możemy Ci zaoferować:",
      list: [
         { listItem: "Angażujące systemy do zgłaszania pomysłów, zagrożeń, problemów połączone z systemami nagród " },
         { listItem: "Na życzenie, połączenie z elementami grywalizacji" },
         { listItem: "Dopasowanie do Twojej marki, rozwiązania szyte na miarę lub na bazie naszych wzorów" },
         { listItem: "Administracja i monitorowanie efektywności programu" },
         { listItem: "Pełne wsparcie technologiczne, bez angażowania działu IT" },
      ],
      color: "#36D058",
   },
];

const ForWho = ({ data }) => {
   const [element, controls] = useScroll();
   return (
      <motion.div variants={scrollReveal} animate={controls} initial="hidden" ref={element}>
         <Tag text={data.tag} />
         <h3>{parse(data.forWhoDescription.heading)}</h3>
         <p>{parse(data.forWhoDescription.paragraph)}</p>
         <CardsContainer className="">
            <Swiper
               slidesPerView={1}
               grid={{
                  rows: 1,
                  fill: "row",
               }}
               spaceBetween={5}
               slidesPerColumnFill="row"
               modules={[Grid, Pagination]}
               breakpoints={{
                  1024: {
                     slidesPerView: 3,
                     spaceBetween: 10,
                     grid: { rows: 1 },
                  },
               }}
               // onInit={(swiper) => {
               //    swiper.init();
               //    swiper.update();
               // }}
               pagination={{
                  clickable: true,
                  el: ".custom-pagination5",
               }}
            >
               {data.cards.map((card) => {
                  return (
                     <SwiperSlide className="">
                        <Card key={card.heading} index={card.heading} theme={{ bgColor: card.color }}>
                           <h3 className="text-right">{card.heading}</h3>
                           <Separator></Separator>
                           <p>{card.body}</p>
                           <ul>
                              {card.list.map((li) => (
                                 <li key={li.listItem}>{li.listItem}</li>
                              ))}
                           </ul>
                        </Card>
                     </SwiperSlide>
                  );
               })}
            </Swiper>
            <PaginationContainerStyled className="custom-pagination5 mt-4 mb-8"></PaginationContainerStyled>
         </CardsContainer>
      </motion.div>
   );
};

export default ForWho;

const Card = styled.div`
   background-color: ${(props) => props.theme.bgColor};
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   border-radius: 16px;
   padding: 74px 24px;
   min-height: 670px;
   color: white;
   p {
      margin-bottom: 16px;
      line-height: 22px;
   }
   ul {
      position: relative;

      margin-left: 0;
      padding-left: 1.2em;
   }
   li {
      list-style: none;
      margin-bottom: 16px;
      line-height: 22px;
   }

   ul li:before {
      content: ${`url(${listStyling})`};
      position: absolute;
      left: 0;
      margin-top: 8px;
   }
`;
const Separator = styled.div`
   border-bottom: 1px solid white;
   margin: 32px 0;
`;
const CardsContainer = styled.div``;
