import React from "react";
import styled from "styled-components";
import agency1 from "../images/trusted-agencies/agency1.png";
import agency2 from "../images/trusted-agencies/agency2.png";
import agency3 from "../images/trusted-agencies/agency3.png";
import agency4 from "../images/trusted-agencies/agency4.png";
import agency5 from "../images/trusted-agencies/agency5.png";
import agency6 from "../images/trusted-agencies/agency6.png";
import agency7 from "../images/trusted-agencies/agency7.png";
import agency8 from "../images/trusted-agencies/agency8.png";

import { Swiper, SwiperSlide } from "swiper/react";

import { PaginationContainerStyled } from "./swiper.styles";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import { Grid, Navigation, Pagination } from "swiper";

const agencies = [agency1, agency2, agency3, agency4, agency5, agency6, agency7, agency8];
const TrustedAgency = ({ agency, index }) => {
   return (
      <SingleAgency className="flex items-center justify-center" theme={index % 2 != 0 ? { bgColor: "#fff" } : { bgColor: "#DBDEE7" }}>
         <img src={agency} alt=" " />
      </SingleAgency>
   );
};

const TrustedAgencies = () => {
   return (
      <div className="">
         <Swiper
            slidesPerView={1}
            grid={{
               rows: 1,
               fill: "row",
            }}
            spaceBetween={5}
            slidesPerColumnFill="row"
            modules={[Grid, Pagination]}
            breakpoints={{
               1024: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                  grid: { rows: 3 },
               },
            }}
            // onInit={(swiper) => {
            //    swiper.init();
            //    swiper.update();
            // }}
            pagination={{
               clickable: true,
               el: ".custom-pagination-agencies",
            }}
         >
            {agencies.map((agency, index) => {
               return (
                  <SwiperSlide className="">
                     <TrustedAgency agency={agency} index={index} />
                  </SwiperSlide>
               );
            })}
         </Swiper>
         <PaginationContainerStyled className="custom-pagination-agencies mt-4  "></PaginationContainerStyled>
      </div>
   );
};

export default TrustedAgencies;
const SingleAgency = styled.div`
   background: ${(props) => props.theme.bgColor};
   border: 1px solid #1c174c;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   border-radius: 16px;
   height: 270px;
`;
