import React, { useRef } from "react";
import styled from "styled-components";
import bgPath from "../../images/box-path-mirrored.png";
// import leftScroll from "../../images/left-hex-scroll.png";
// import rightScroll from "../../images/right-hex-scroll.png";
import parse from "html-react-parser";
import { ReactComponent as LScroll } from "../../images/scroll_L.svg";
import { ReactComponent as RScroll } from "../../images/scroll_R.svg";
import ourWorkImg from "../../images/our-work-placeholder.png";
import Tag from "../Tag";
import { motion } from "framer-motion";
import { useScroll } from "../useScroll";
import { scrollReveal } from "../../animations";

import { Swiper, SwiperSlide } from "swiper/react";

import { PaginationContainerStyled } from "../swiper.styles";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import { Grid, Navigation, Pagination } from "swiper";
export default function OurWork({ data }) {
   const prevRef = useRef(null);
   const nextRef = useRef(null);
   const [element, controls] = useScroll();
   return (
      <Wrapper variants={scrollReveal} animate={controls} initial="hidden" ref={element}>
         <div className="container mx-auto ">
            <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 lg:gap-24 ">
               <div className="lg:col-span-7 order-last lg:order-first">
                  <Container className="">
                     <Swiper
                        slidesPerView={1}
                        grid={{
                           rows: 1,
                           fill: "row",
                        }}
                        spaceBetween={5}
                        slidesPerColumnFill="row"
                        modules={[Grid, Pagination, Navigation]}
                        breakpoints={{
                           1024: {
                              slidesPerView: 1,
                              spaceBetween: 10,
                              grid: { rows: 1 },
                           },
                        }}
                        navigation={{
                           prevEl: prevRef.current,
                           nextEl: nextRef.current,
                        }}
                        onInit={(swiper) => {
                           swiper.params.navigation.prevEl = prevRef.current;
                           swiper.params.navigation.nextEl = nextRef.current;
                           swiper.navigation.init();
                           swiper.navigation.update();
                        }}
                        pagination={{
                           clickable: true,
                           el: ".custom-pagination3",
                        }}
                     >
                        <SwiperSlide className="">
                           <ContentWraper className=""></ContentWraper>
                        </SwiperSlide>
                        <SwiperSlide className="">
                           <ContentWraper className=""></ContentWraper>
                        </SwiperSlide>
                        <SwiperSlide className="">
                           <ContentWraper className=""></ContentWraper>
                        </SwiperSlide>
                        <SwiperSlide className="">
                           <ContentWraper className=""></ContentWraper>
                        </SwiperSlide>
                     </Swiper>
                     <PaginationContainerStyled className="custom-pagination3 mt-4  "></PaginationContainerStyled>
                  </Container>
               </div>

               <div className="lg:col-span-5 ">
                  <div className="absolute-center">
                     <Tag text={data.tag} />
                     <h3>{parse(data.ourWorkDescription.heading)}</h3>
                     <p>{parse(data.ourWorkDescription.paragraph)}</p>
                     <div className=" gap-2 hidden lg:flex">
                        {/* <img src={leftScroll} alt=" " ref={prevRef} className={"cursor-pointer"} />
                        <img src={rightScroll} alt=" " ref={nextRef} className={"cursor-pointer"} /> */}
                        <LScroll className="scroll-button" ref={prevRef} />
                        <RScroll className="scroll-button" ref={nextRef} />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Wrapper>
   );
}
const ContentWraper = styled.div`
   background-image: ${`url(${ourWorkImg})`};
   background-repeat: no-repeat;
   background-size: contain;
   height: 100%;
   width: 88%;
   margin-left: 11%;
   height: 700px;
   background-position: center;
   border-radius: 24px;
   @media only screen and (max-width: 1024px) {
      /* min-height: 400px; */
      height: 300px;
      background-size: cover;
      width: 100%;
      margin-left: 0;
   }
`;
const Wrapper = styled(motion.div)`
   position: relative;
   ::before {
      position: absolute;
      content: "";
      z-index: -1;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 380px;
      background-color: #f5f8fd;
      @media only screen and (max-width: 1024px) {
         background: none;
      }
   }
   @media only screen and (max-width: 1024px) {
      background-color: #f5f8fd;
      padding: 40px 0 40px 0;
   }
`;
const Container = styled.div`
   background-image: ${`url(${bgPath})`};
   background-repeat: no-repeat;
   height: 700px;
   /* background-size: contain; */
   background-position: center right;
   background-position: 0px center;
   background-size: 88%;
   @media only screen and (max-width: 1024px) {
      background-image: none;
      height: auto;
   }
`;
